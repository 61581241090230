export const LANDING = '/';
export const NEW_USER = '/signup';
export const SIGN_IN = '/signin';
export const CLASSES = '/classes';
export const CLASS = '/classes/:key';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const LAUNCHPAD = '/launchpad';
export const UNIVERSE = '/universe';
export const UNIVERSE_PUBLISH = '/universe/:key';
export const UNTUTORIAL = '/launchpad/:key';
export const PROFILE = '/profile/:key';
export const NEW_PROJECT = '/new_project'
export const NEW_CLASS = '/new_class'
export const RESOURCES = '/resources/:key'
export const RESOURCE_HOME = '/resources'
export const SIMULATOR = '/simulator';
export const PROGRESSREVIEWS = '/student-progress';
export const JETFUEL = '/jetfuel';
export const QUESTION = '/jetfuel/:key';
export const GMAIL_WEBSERVICE = 'https://script.google.com/macros/s/AKfycbwsPknVjy79bpFItel6pry6khfvlHWT3UKuY3tQNqnldRVaXHc0/exec';